/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n        query AppDetailLoadAppInfo($app: ID!) {\n          getApp(pk: $app) {\n            pk\n            title\n            name\n            description\n            repository\n            homepage\n            readme\n            owner {\n              author\n            }\n          }\n        }\n      ": types.AppDetailLoadAppInfoDocument,
    "\n        query AppDetailLoadPricings($app: ID!) {\n          getApp(pk: $app) {\n            pricingPlans {\n              ...AppDetailPricing\n            }\n          }\n        }\n        fragment AppDetailPricing on Pricing {\n          name\n          callToAction\n          minMonthlyCharge\n          chargePerRequest\n          freeQuota\n        }\n      ": types.AppDetailLoadPricingsDocument,
    "\n        query AppDetailLoadEndpoints($app: ID!) {\n          getApp(pk: $app) {\n            endpoints {\n              ...AppDetailEndpoint\n            }\n          }\n        }\n        fragment AppDetailEndpoint on Endpoint {\n          method\n          path\n          description\n        }\n      ": types.AppDetailLoadEndpointsDocument,
    "\n        query appFullTextSearch($query: String, $tag: String, $orderBy: AppFullTextSearchOrderBy) {\n          appFullTextSearch(query: $query, tag: $tag, orderBy: $orderBy) {\n            ...AppSearchResult\n          }\n        }\n        fragment AppSearchResult on App {\n          pk\n          name\n          title\n          owner {\n            author\n          }\n          description\n        }\n      ": types.AppFullTextSearchDocument,
    "\n        query GetAccountInfo($user: ID!) {\n          getUser(pk: $user) {\n            email\n            balance\n            author\n          }\n        }\n      ": types.GetAccountInfoDocument,
    "\n        query GetAccountActivities($user: ID!, $dateRange: DateRangeInput!) {\n          listAccountActivitiesByUser(user: $user, dateRange: $dateRange, limit: 200) {\n            ...DashboardAccountActivity\n          }\n        }\n        fragment DashboardAccountActivity on AccountActivity {\n          createdAt\n          type\n          amount\n          reason\n          description\n          status\n          settleAt\n          consumedFreeQuota\n          billedApp {\n            name\n          }\n          usageSummary {\n            volume\n          }\n          stripeTransfer {\n            transferAt\n            status\n          }\n        }\n      ": types.GetAccountActivitiesDocument,
    "\n        query GetAccountHistories($user: ID!, $dateRange: DateRangeInput!) {\n          listAccountHistoryByUser(user: $user, dateRange: $dateRange, limit: 200) {\n            ...DashboardAccountHistory\n          }\n        }\n        fragment DashboardAccountHistory on AccountHistory {\n          closingBalance\n          closingTime\n        }\n      ": types.GetAccountHistoriesDocument,
    "\n        query GetFeaturedProducts {\n          listAppsByTag(tag: \"Featured\") {\n            ...FeaturedProduct\n          }\n        }\n\n        fragment FeaturedProduct on App {\n          pk\n          name\n          logo\n          title\n          description\n        }\n      ": types.GetFeaturedProductsDocument,
    "\n        query HomePageGetLatestProducts {\n          listAppsByTag(tag: \"Latest\") {\n            ...LatestProduct\n          }\n        }\n\n        fragment LatestProduct on App {\n          pk\n          name\n          logo\n          title\n          description\n        }\n      ": types.HomePageGetLatestProductsDocument,
    "\n        query HomePageGetPricingData(\n          $perRequestChargeKey: String!\n          $stripeFlatFeeKey: String!\n          $stripePercentageFeeKey: String!\n        ) {\n          preRequestCharge: getSiteMetaDataByKey(key: $perRequestChargeKey) {\n            key\n            value\n          }\n          stripeFlatFee: getSiteMetaDataByKey(key: $stripeFlatFeeKey) {\n            key\n            value\n          }\n          stripePercentageFee: getSiteMetaDataByKey(key: $stripePercentageFeeKey) {\n            key\n            value\n          }\n        }\n      ": types.HomePageGetPricingDataDocument,
    "\n        query MyAppGetDetail($appName: String!) {\n          getAppByName(name: $appName) {\n            name\n            title\n            description\n            repository\n            homepage\n            readme\n            visibility\n            pricingPlans {\n              pk\n              name\n              minMonthlyCharge\n              chargePerRequest\n              freeQuota\n              callToAction\n            }\n            endpoints {\n              pk\n              path\n              description\n              destination\n              method\n            }\n          }\n        }\n      ": types.MyAppGetDetailDocument,
    "\n        query GetUserApps($user: ID!) {\n          getUser(pk: $user) {\n            author\n            apps {\n              name\n              description\n              title\n              updatedAt\n            }\n          }\n        }\n      ": types.GetUserAppsDocument,
    "\n        query GetAvailablePlans($appName: String!) {\n          getAppByName(name: $appName) {\n            pricingPlans {\n              name\n              pk\n              minMonthlyCharge\n              chargePerRequest\n              freeQuota\n              callToAction\n            }\n          }\n        }\n      ": types.GetAvailablePlansDocument,
    "\n        query GetUserSubscriptionDetail($user: ID!, $appName: ID!) {\n          getSubscriptionByAppSubscriber(subscriber: $user, app: $appName) {\n            pricing {\n              pk\n              name\n            }\n          }\n        }\n      ": types.GetUserSubscriptionDetailDocument,
    "\n        query GetSubscriptionDetailAppInfo($appName: String!) {\n          getAppByName(name: $appName) {\n            name\n            title\n            description\n          }\n        }\n      ": types.GetSubscriptionDetailAppInfoDocument,
    "\n        query GetUsageSummary($user: ID!, $appName: ID!, $dateRange: DateRangeInput) {\n          getUser(pk: $user) {\n            usageSummaries(app: $appName, dateRange: $dateRange) {\n              createdAt\n              volume\n              billingRequestChargeAccountActivity {\n                amount\n              }\n            }\n          }\n        }\n      ": types.GetUsageSummaryDocument,
    "\n        query GetUserSubscriptions($user: ID!) {\n          getUser(pk: $user) {\n            subscriptions {\n              pk\n              updatedAt\n              pricing {\n                name\n              }\n              app {\n                title\n                name\n                owner {\n                  author\n                }\n              }\n            }\n          }\n        }\n      ": types.GetUserSubscriptionsDocument,
    "\n    query TermsPageGetPricingData(\n      $perRequestChargeKey: String!\n      $stripeFlatFeeKey: String!\n      $stripePercentageFeeKey: String!\n    ) {\n      perRequestCharge: getSiteMetaDataByKey(key: $perRequestChargeKey) {\n        key\n        value\n      }\n      stripeFlatFee: getSiteMetaDataByKey(key: $stripeFlatFeeKey) {\n        key\n        value\n      }\n      stripePercentageFee: getSiteMetaDataByKey(key: $stripePercentageFeeKey) {\n        key\n        value\n      }\n    }\n  ": types.TermsPageGetPricingDataDocument,
    "\n      query GetUserPKByEmail($email: Email!) {\n        getUserByEmail(email: $email) {\n          pk\n        }\n      }\n    ": types.GetUserPkByEmailDocument,
    "\n      mutation PutSecret($key: String!, $signedValue: String!, $description: String, $expireAt: Timestamp) {\n        createSecret(key: $key, value: $signedValue, description: $description, expireAt: $expireAt) {\n          createdAt\n        }\n      }\n    ": types.PutSecretDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query AppDetailLoadAppInfo($app: ID!) {\n          getApp(pk: $app) {\n            pk\n            title\n            name\n            description\n            repository\n            homepage\n            readme\n            owner {\n              author\n            }\n          }\n        }\n      "): (typeof documents)["\n        query AppDetailLoadAppInfo($app: ID!) {\n          getApp(pk: $app) {\n            pk\n            title\n            name\n            description\n            repository\n            homepage\n            readme\n            owner {\n              author\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query AppDetailLoadPricings($app: ID!) {\n          getApp(pk: $app) {\n            pricingPlans {\n              ...AppDetailPricing\n            }\n          }\n        }\n        fragment AppDetailPricing on Pricing {\n          name\n          callToAction\n          minMonthlyCharge\n          chargePerRequest\n          freeQuota\n        }\n      "): (typeof documents)["\n        query AppDetailLoadPricings($app: ID!) {\n          getApp(pk: $app) {\n            pricingPlans {\n              ...AppDetailPricing\n            }\n          }\n        }\n        fragment AppDetailPricing on Pricing {\n          name\n          callToAction\n          minMonthlyCharge\n          chargePerRequest\n          freeQuota\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query AppDetailLoadEndpoints($app: ID!) {\n          getApp(pk: $app) {\n            endpoints {\n              ...AppDetailEndpoint\n            }\n          }\n        }\n        fragment AppDetailEndpoint on Endpoint {\n          method\n          path\n          description\n        }\n      "): (typeof documents)["\n        query AppDetailLoadEndpoints($app: ID!) {\n          getApp(pk: $app) {\n            endpoints {\n              ...AppDetailEndpoint\n            }\n          }\n        }\n        fragment AppDetailEndpoint on Endpoint {\n          method\n          path\n          description\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query appFullTextSearch($query: String, $tag: String, $orderBy: AppFullTextSearchOrderBy) {\n          appFullTextSearch(query: $query, tag: $tag, orderBy: $orderBy) {\n            ...AppSearchResult\n          }\n        }\n        fragment AppSearchResult on App {\n          pk\n          name\n          title\n          owner {\n            author\n          }\n          description\n        }\n      "): (typeof documents)["\n        query appFullTextSearch($query: String, $tag: String, $orderBy: AppFullTextSearchOrderBy) {\n          appFullTextSearch(query: $query, tag: $tag, orderBy: $orderBy) {\n            ...AppSearchResult\n          }\n        }\n        fragment AppSearchResult on App {\n          pk\n          name\n          title\n          owner {\n            author\n          }\n          description\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetAccountInfo($user: ID!) {\n          getUser(pk: $user) {\n            email\n            balance\n            author\n          }\n        }\n      "): (typeof documents)["\n        query GetAccountInfo($user: ID!) {\n          getUser(pk: $user) {\n            email\n            balance\n            author\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetAccountActivities($user: ID!, $dateRange: DateRangeInput!) {\n          listAccountActivitiesByUser(user: $user, dateRange: $dateRange, limit: 200) {\n            ...DashboardAccountActivity\n          }\n        }\n        fragment DashboardAccountActivity on AccountActivity {\n          createdAt\n          type\n          amount\n          reason\n          description\n          status\n          settleAt\n          consumedFreeQuota\n          billedApp {\n            name\n          }\n          usageSummary {\n            volume\n          }\n          stripeTransfer {\n            transferAt\n            status\n          }\n        }\n      "): (typeof documents)["\n        query GetAccountActivities($user: ID!, $dateRange: DateRangeInput!) {\n          listAccountActivitiesByUser(user: $user, dateRange: $dateRange, limit: 200) {\n            ...DashboardAccountActivity\n          }\n        }\n        fragment DashboardAccountActivity on AccountActivity {\n          createdAt\n          type\n          amount\n          reason\n          description\n          status\n          settleAt\n          consumedFreeQuota\n          billedApp {\n            name\n          }\n          usageSummary {\n            volume\n          }\n          stripeTransfer {\n            transferAt\n            status\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetAccountHistories($user: ID!, $dateRange: DateRangeInput!) {\n          listAccountHistoryByUser(user: $user, dateRange: $dateRange, limit: 200) {\n            ...DashboardAccountHistory\n          }\n        }\n        fragment DashboardAccountHistory on AccountHistory {\n          closingBalance\n          closingTime\n        }\n      "): (typeof documents)["\n        query GetAccountHistories($user: ID!, $dateRange: DateRangeInput!) {\n          listAccountHistoryByUser(user: $user, dateRange: $dateRange, limit: 200) {\n            ...DashboardAccountHistory\n          }\n        }\n        fragment DashboardAccountHistory on AccountHistory {\n          closingBalance\n          closingTime\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetFeaturedProducts {\n          listAppsByTag(tag: \"Featured\") {\n            ...FeaturedProduct\n          }\n        }\n\n        fragment FeaturedProduct on App {\n          pk\n          name\n          logo\n          title\n          description\n        }\n      "): (typeof documents)["\n        query GetFeaturedProducts {\n          listAppsByTag(tag: \"Featured\") {\n            ...FeaturedProduct\n          }\n        }\n\n        fragment FeaturedProduct on App {\n          pk\n          name\n          logo\n          title\n          description\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query HomePageGetLatestProducts {\n          listAppsByTag(tag: \"Latest\") {\n            ...LatestProduct\n          }\n        }\n\n        fragment LatestProduct on App {\n          pk\n          name\n          logo\n          title\n          description\n        }\n      "): (typeof documents)["\n        query HomePageGetLatestProducts {\n          listAppsByTag(tag: \"Latest\") {\n            ...LatestProduct\n          }\n        }\n\n        fragment LatestProduct on App {\n          pk\n          name\n          logo\n          title\n          description\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query HomePageGetPricingData(\n          $perRequestChargeKey: String!\n          $stripeFlatFeeKey: String!\n          $stripePercentageFeeKey: String!\n        ) {\n          preRequestCharge: getSiteMetaDataByKey(key: $perRequestChargeKey) {\n            key\n            value\n          }\n          stripeFlatFee: getSiteMetaDataByKey(key: $stripeFlatFeeKey) {\n            key\n            value\n          }\n          stripePercentageFee: getSiteMetaDataByKey(key: $stripePercentageFeeKey) {\n            key\n            value\n          }\n        }\n      "): (typeof documents)["\n        query HomePageGetPricingData(\n          $perRequestChargeKey: String!\n          $stripeFlatFeeKey: String!\n          $stripePercentageFeeKey: String!\n        ) {\n          preRequestCharge: getSiteMetaDataByKey(key: $perRequestChargeKey) {\n            key\n            value\n          }\n          stripeFlatFee: getSiteMetaDataByKey(key: $stripeFlatFeeKey) {\n            key\n            value\n          }\n          stripePercentageFee: getSiteMetaDataByKey(key: $stripePercentageFeeKey) {\n            key\n            value\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query MyAppGetDetail($appName: String!) {\n          getAppByName(name: $appName) {\n            name\n            title\n            description\n            repository\n            homepage\n            readme\n            visibility\n            pricingPlans {\n              pk\n              name\n              minMonthlyCharge\n              chargePerRequest\n              freeQuota\n              callToAction\n            }\n            endpoints {\n              pk\n              path\n              description\n              destination\n              method\n            }\n          }\n        }\n      "): (typeof documents)["\n        query MyAppGetDetail($appName: String!) {\n          getAppByName(name: $appName) {\n            name\n            title\n            description\n            repository\n            homepage\n            readme\n            visibility\n            pricingPlans {\n              pk\n              name\n              minMonthlyCharge\n              chargePerRequest\n              freeQuota\n              callToAction\n            }\n            endpoints {\n              pk\n              path\n              description\n              destination\n              method\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetUserApps($user: ID!) {\n          getUser(pk: $user) {\n            author\n            apps {\n              name\n              description\n              title\n              updatedAt\n            }\n          }\n        }\n      "): (typeof documents)["\n        query GetUserApps($user: ID!) {\n          getUser(pk: $user) {\n            author\n            apps {\n              name\n              description\n              title\n              updatedAt\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetAvailablePlans($appName: String!) {\n          getAppByName(name: $appName) {\n            pricingPlans {\n              name\n              pk\n              minMonthlyCharge\n              chargePerRequest\n              freeQuota\n              callToAction\n            }\n          }\n        }\n      "): (typeof documents)["\n        query GetAvailablePlans($appName: String!) {\n          getAppByName(name: $appName) {\n            pricingPlans {\n              name\n              pk\n              minMonthlyCharge\n              chargePerRequest\n              freeQuota\n              callToAction\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetUserSubscriptionDetail($user: ID!, $appName: ID!) {\n          getSubscriptionByAppSubscriber(subscriber: $user, app: $appName) {\n            pricing {\n              pk\n              name\n            }\n          }\n        }\n      "): (typeof documents)["\n        query GetUserSubscriptionDetail($user: ID!, $appName: ID!) {\n          getSubscriptionByAppSubscriber(subscriber: $user, app: $appName) {\n            pricing {\n              pk\n              name\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetSubscriptionDetailAppInfo($appName: String!) {\n          getAppByName(name: $appName) {\n            name\n            title\n            description\n          }\n        }\n      "): (typeof documents)["\n        query GetSubscriptionDetailAppInfo($appName: String!) {\n          getAppByName(name: $appName) {\n            name\n            title\n            description\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetUsageSummary($user: ID!, $appName: ID!, $dateRange: DateRangeInput) {\n          getUser(pk: $user) {\n            usageSummaries(app: $appName, dateRange: $dateRange) {\n              createdAt\n              volume\n              billingRequestChargeAccountActivity {\n                amount\n              }\n            }\n          }\n        }\n      "): (typeof documents)["\n        query GetUsageSummary($user: ID!, $appName: ID!, $dateRange: DateRangeInput) {\n          getUser(pk: $user) {\n            usageSummaries(app: $appName, dateRange: $dateRange) {\n              createdAt\n              volume\n              billingRequestChargeAccountActivity {\n                amount\n              }\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query GetUserSubscriptions($user: ID!) {\n          getUser(pk: $user) {\n            subscriptions {\n              pk\n              updatedAt\n              pricing {\n                name\n              }\n              app {\n                title\n                name\n                owner {\n                  author\n                }\n              }\n            }\n          }\n        }\n      "): (typeof documents)["\n        query GetUserSubscriptions($user: ID!) {\n          getUser(pk: $user) {\n            subscriptions {\n              pk\n              updatedAt\n              pricing {\n                name\n              }\n              app {\n                title\n                name\n                owner {\n                  author\n                }\n              }\n            }\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query TermsPageGetPricingData(\n      $perRequestChargeKey: String!\n      $stripeFlatFeeKey: String!\n      $stripePercentageFeeKey: String!\n    ) {\n      perRequestCharge: getSiteMetaDataByKey(key: $perRequestChargeKey) {\n        key\n        value\n      }\n      stripeFlatFee: getSiteMetaDataByKey(key: $stripeFlatFeeKey) {\n        key\n        value\n      }\n      stripePercentageFee: getSiteMetaDataByKey(key: $stripePercentageFeeKey) {\n        key\n        value\n      }\n    }\n  "): (typeof documents)["\n    query TermsPageGetPricingData(\n      $perRequestChargeKey: String!\n      $stripeFlatFeeKey: String!\n      $stripePercentageFeeKey: String!\n    ) {\n      perRequestCharge: getSiteMetaDataByKey(key: $perRequestChargeKey) {\n        key\n        value\n      }\n      stripeFlatFee: getSiteMetaDataByKey(key: $stripeFlatFeeKey) {\n        key\n        value\n      }\n      stripePercentageFee: getSiteMetaDataByKey(key: $stripePercentageFeeKey) {\n        key\n        value\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query GetUserPKByEmail($email: Email!) {\n        getUserByEmail(email: $email) {\n          pk\n        }\n      }\n    "): (typeof documents)["\n      query GetUserPKByEmail($email: Email!) {\n        getUserByEmail(email: $email) {\n          pk\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation PutSecret($key: String!, $signedValue: String!, $description: String, $expireAt: Timestamp) {\n        createSecret(key: $key, value: $signedValue, description: $description, expireAt: $expireAt) {\n          createdAt\n        }\n      }\n    "): (typeof documents)["\n      mutation PutSecret($key: String!, $signedValue: String!, $description: String, $expireAt: Timestamp) {\n        createSecret(key: $key, value: $signedValue, description: $description, expireAt: $expireAt) {\n          createdAt\n        }\n      }\n    "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;