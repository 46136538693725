/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const EnvVars = t.iface([], {
  "DEV_DOMAIN": t.opt(t.union(t.lit("1"), t.lit("0"))),
  "LOCAL_GRAPHQL": t.opt(t.union(t.lit("1"), t.lit("0"))),
  "LOCAL_DOC": t.opt(t.union(t.lit("1"), t.lit("0"))),
  "LOG": t.opt(t.union(t.lit("0"), t.lit("1"), t.lit("2"), t.lit("3"))),
});

const exportedTypeSuite: t.ITypeSuite = {
  EnvVars,
};
export default exportedTypeSuite;
